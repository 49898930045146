<template>
  <default-layout :title="`Project: ${projectName}`">
    <v-container fluid tag="section">
      <unit-type-form />
    </v-container>
  </default-layout>
</template>

<script>
import DefaultLayout from '@/layouts/default';
const UnitTypeForm = () => import('@/components/project-management/unit-type/form/unit-type-form');

export default {
  name: 'unit-type-edit',
  components: { DefaultLayout, UnitTypeForm },
  async created() {
    await this.$store.dispatch('unitType/form/restoreInitialState');
    this.$store.commit('unitType/form/SET_FORM_TYPE', 'EDIT');
    this.$store.commit('unitType/form/SET_UUID', this.$route.params.uuid);
    this.$store.dispatch('unitType/form/fetchBaseData');
  },
  computed: {
    projectName() {
      return this.$store.state.unitType.form.projectName;
    },
  },
};
</script>

<style scoped></style>
